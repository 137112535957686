<template>
  <div>
    <div class="subheader py-2 py-lg-6 subheader-transparent">
      <div
        class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap p-0"
      >
        <!--begin::Page Title-->
        <div class="d-flex flex-column text-left">
          <h5 class="text-dark font-weight-bold my-1 mr-5">
            {{
              this.$route.query.type == "single"
                ? this.$t("admin.form.update_user")
                : this.$t("admin.form.multi_update_user") +
                  `(${this.$route.params.users.length})`
            }}
          </h5>
          <span v-if="this.$route.query.type == 'multiple'">
            {{ $t("admin.popup.update.info") }}
          </span>
        </div>
        <!--end::Page Title-->
        <b-button
          @click="$router.push({ name: 'UsersList' })"
          size="sm"
          pill
          variant="secondary"
          class="font-weight-bolder mr-2 text-uppercase"
        >
          <div class="d-flex">
            <i class="fa fa-chevron-left" />
            <span style=" white-space: nowrap">
              {{ $t("generic.buttons.back") }}
            </span>
          </div>
        </b-button>
      </div>
    </div>
    <basic-form
      :form="form"
      :fields="fields"
      :save="save"
      :error="error"
      :errors="errors"
      :isSubmitting="isSubmitting"
      v-if="loaded"
    />
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";
import { getConstants, getTimezones } from "@/api/misc.api";

import {
  getAdminStakeholder,
  getDepartments,
  getCompanies,
  updateStakeholder
} from "@/api/admin/stakeholders.api";

import BasicForm from "@/components/form/BasicForm.component";
import { validRFC5322, validText, validPhone } from "@/utils/validators";
import { getEditedProfiles, parseProfile } from "@/utils/helpers";
import { getError } from "@/utils/helpers";

export default {
  name: "UserUpdate",
  components: {
    BasicForm
  },
  data() {
    return {
      user: {},
      loaded: false,
      errors: [],
      error: false,
      timezones: [],
      form: {},
      departments: [],
      authenticationMethods: [],
      profiles: [],
      companiesProfiles: [],
      isSubmitting: false,
      correct: [],
      tableFields: [
        {
          key: "name",
          label: this.$t(`admin.form.name.label`),
          sortable: true,
          stickyColumn: true,
          isRowHeader: true,
          thClass: "col-4"
        },
        {
          key: "controller",
          label: this.$t(`admin.form.profile_options.operator`),
          thClass: "col-4",
          sortable: true
        },
        {
          key: "admin",
          label: this.$t(`admin.form.profile_options.admin`),
          thClass: "col-4",
          sortable: true
        }
      ]
    };
  },
  methods: {
    ...mapActions("Admin", [
      "loadUsers",
      "reset",
      "loadCompanySelected",
      "loadDepartments"
    ]),
    callGetError(code, params) {
      return getError(code, params);
    },
    save(data, checked = []) {
      this.isSubmitting = true;

      const managedCompanyId = localStorage.getItem("managedCompanyId");
      if (this.$route.query.type == "single") {
        let now = moment();
        let dateLaunchSurvey = moment(data.dateLaunchSurvey)
          .subtract(1, "days")
          .set({
            hour: now.hour(),
            minute: now.minute(),
            second: now.second()
          });

        const formattedForm = {
          name: data.name,
          surname: data.surname,
          isBoss: data.isBoss | false,
          email: data.email,
          login: data.login,
          stakeholderCompanyId: managedCompanyId,
          environment: {
            notifications: {
              sendemail: data.sendemail ? "YES" : "NO"
            },
            languages: {
              default: data.language
            },
            timezone: data.timezone
          },
          surveyId: this.getConstant("SURVEY_KYMATIO_WELCOME"),
          phoneNumber: data.phoneNumber || "",
          authentication: data.authentication.id
        };

        if (data.dateLaunchSurveyEnabled) {
          formattedForm.dateLaunchSurvey = dateLaunchSurvey.format(
            "YYYY-MM-DD HH:mm:ss"
          );
        }

        if (data.department?.id) {
          formattedForm.stakeholderDepartmentId = data.department.id;
        }

        let profilesResult = {};
        let profiles = getEditedProfiles(
          this.form.profile,
          this.companySelected.stakeholderId
        );
        _.forOwn(profiles, (value, key) => {
          if (profiles[key].length > 0) {
            profilesResult = { ...profilesResult, [key]: value };
          }
        });
        if (
          data.assignated_profiles["ADMIN"]?.length > 0 ||
          profilesResult["ADMIN"]?.length > 0
        ) {
          profilesResult["ADMIN"] = _.uniq(
            _.concat(
              _.toArray(profilesResult["ADMIN"]),
              _.toArray(data.assignated_profiles["ADMIN"])
            )
          );
        } else {
          _.unset(profilesResult, "ADMIN");
        }
        if (
          data.assignated_profiles["CONTROLLER"]?.length > 0 ||
          profilesResult["CONTROLLER"]?.length > 0
        ) {
          profilesResult["CONTROLLER"] = _.uniq(
            _.concat(
              _.toArray(profilesResult["CONTROLLER"]),
              _.toArray(data.assignated_profiles["CONTROLLER"])
            )
          );
        } else {
          _.unset(profilesResult, "CONTROLLER");
        }
        formattedForm.profiles = profilesResult;

        if (!this.errors.length) {
          updateStakeholder("people", this.$route.params.id, formattedForm)
            .then(response => {
              this.$root.$bvToast.toast(
                this.$t("admin.form.success.update_user_body", {
                  user: response.data.records.name
                }),
                {
                  title: this.$t("admin.form.success.update_user_header"),
                  variant: "success",
                  solid: true,
                  toaster: "b-toaster-top-center"
                }
              );
              this.loadCompanySelected();
              this.loadUsers();
              this.loadDepartments();
              this.$router.push({
                name: "UsersList"
              });
              this.isSubmitting = false;
            })
            .catch(error => {
              this.errors.push({
                name: error.response.data.records.userMessage,
                code: error.response.data.records.applicationCode,
                entity: "user"
              });
              this.$root.$bvToast.toast(
                this.callGetError(error.response.data.records.applicationCode),
                {
                  title: this.callGetError("update_user", {
                    user: this.form.name
                  }),
                  variant: "danger",
                  solid: true,
                  toaster: "b-toaster-top-center"
                }
              );
              this.isSubmitting = false;
            });
        } else {
          this.isSubmitting = false;
        }
      } else {
        let formattedForm = {
          stakeholderCompanyId: managedCompanyId
        };

        if (checked["department"] && data.department?.id) {
          formattedForm.stakeholderDepartmentId = data.department.id;
        }

        if (checked["sendemail"]) {
          formattedForm.environment = {
            ...formattedForm.environment,
            notifications: { sendemail: data.sendemail ? "YES" : "NO" }
          };
        }

        if (checked["language"] && data.language) {
          formattedForm.environment = {
            ...formattedForm.environment,
            languages: { default: data.language }
          };
        }

        if (checked["authenticated"]) {
          formattedForm = {
            ...formattedForm,
            authenticated: data.authenticated ? true : false
          };
        }

        if (checked["timezone"] && data.timezone) {
          formattedForm.environment = {
            ...formattedForm.environment,
            timezone: data.timezone
          };
        }

        if (checked["isBoss"]) {
          formattedForm.isBoss = data.isBoss | false;
        }

        if (checked["expiration"]) {
          formattedForm.environment = {
            ...formattedForm.environment,
            passwords: {
              ...formattedForm.environment?.passwords,
              expiration: data.expiration
            }
          };
        }

        if (checked["amount"]) {
          formattedForm.environment = {
            ...formattedForm.environment,
            passwords: {
              ...formattedForm.environment?.passwords,
              amount: data.amount
            }
          };
        }

        if (checked["manager"]) {
          formattedForm.environment.notifications = {
            ...formattedForm.environment.notifications,
            period: {
              ...formattedForm.environment?.notifications?.period,
              manager: data.manager
            }
          };
        }

        if (checked["user"]) {
          formattedForm.environment.notifications = {
            ...formattedForm.environment.notifications,
            period: {
              ...formattedForm.environment?.notifications?.period,
              user: data.user
            }
          };
        }

        if (checked["dateLaunchSurvey"]) {
          let now = moment();
          let dateLaunchSurvey = moment(data.dateLaunchSurvey)
            .subtract(1, "days")
            .set({
              hour: now.hour(),
              minute: now.minute(),
              second: now.second()
            });
          formattedForm = {
            ...formattedForm,
            dateLaunchSurvey: dateLaunchSurvey.format("YYYY-MM-DD HH:mm:ss"),
            surveyId: this.getConstant("SURVEY_KYMATIO_WELCOME")
          };
        }

        if (checked["authentication"] && data.authentication?.id) {
          formattedForm.authentication = data.authentication.id;
        }

        if (!this.errors.length) {
          const toObject = Object.fromEntries(Object.entries(checked));
          const areChecked = Object.values(toObject).filter(
            element => element == true
          ).length;

          if (areChecked == 0) {
            this.$swal({
              html: this.$t("admin.popup.update.no_fields_checked"),
              icon: "warning",
              confirmButtonColor: "#0BB783",
              confirmButtonText: this.$t("admin.popup.aceptButton")
            });
            this.isSubmitting = false;
          } else {
            this.$swal({
              html: this.$t("admin.popup.update.multi_user", {
                fields: areChecked,
                users: this.$route.params.users.length,
                confirmation: this.$t("admin.popup.update.update_confirmation")
              }),
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#0BB783",
              confirmButtonText: this.$t("admin.popup.confirmButton"),
              cancelButtonText: this.$t("admin.popup.cancelButton"),
              input: "text",
              inputValidator: value => {
                if (
                  value === this.$t("admin.popup.update.update_confirmation")
                ) {
                  var limit = 200;
                  var userIds = this.$route.params.users;

                  const sendRequestsInBatches = (ids, startIndex) => {
                    const batchIds = _.slice(
                      ids,
                      startIndex,
                      startIndex + limit
                    );
                    if (batchIds.length === 0) {
                      this.isSubmitting = false;
                      this.$root.$bvToast.toast(
                        this.$t("admin.form.success.update_users_body"),
                        {
                          title: this.$t(
                            "admin.form.success.update_users_headers",
                            {
                              users: this.correct.length
                            }
                          ),
                          variant: "success",
                          solid: true,
                          toaster: "b-toaster-top-center"
                        }
                      );
                      this.loadUsers();
                      this.$router.push({
                        name: "UsersList"
                      });
                      return;
                    }

                    let requests = [];

                    _.each(batchIds, id => {
                      let paramsToSend = _.clone(formattedForm);
                      paramsToSend.stakeholdersId = [id];
                      requests.push(
                        updateStakeholder("people", id, paramsToSend)
                      );
                    });

                    let incorrect = [];

                    axios
                      .all(requests)
                      .then(
                        axios.spread((...responses) => {
                          _.each(responses, response => {
                            let data = JSON.parse(response.config.data);
                            if (response.status === 200) {
                              this.correct.push(...data.stakeholdersId);
                            } else {
                              incorrect.push(...data.stakeholdersId);
                            }
                          });

                          sendRequestsInBatches(ids, startIndex + limit);
                        })
                      )
                      .catch(() => {
                        this.errors.push({
                          name: "Incorrect format",
                          code: "ER6667",
                          entity: "user"
                        });
                        this.$root.$bvToast.toast(this.callGetError("ER6667"), {
                          title: this.callGetError("update_users"),
                          variant: "danger",
                          solid: true,
                          toaster: "b-toaster-top-center"
                        });

                        sendRequestsInBatches(ids, startIndex + limit);
                      });
                  };
                  sendRequestsInBatches(userIds, 0);
                } else {
                  this.isSubmitting = false;
                  return this.$t("admin.popup.incorrectWord");
                }
              }
            });
          }
        }
      }
    },
    calculateProfiles(profiles) {
      const stakeholderId = this.companySelected.stakeholderId;
      let oldProfiles = this.user.profiles;
      let editedProfiles = getEditedProfiles(profiles, stakeholderId);
      let result = {};

      _.forOwn(editedProfiles, (value, key) => {
        if (oldProfiles[key] != undefined) {
          let isInOld = oldProfiles[key].includes(Number(stakeholderId));
          let isInEdit = editedProfiles[key].includes(Number(stakeholderId));

          if (isInOld && !isInEdit) {
            oldProfiles[key] = oldProfiles[key].filter(
              element => element != stakeholderId
            );
          } else if (!isInOld && isInEdit) {
            oldProfiles[key] = [...oldProfiles[key], stakeholderId];
          }
        } else {
          if (editedProfiles[key].length > 0) {
            oldProfiles[key] = [stakeholderId];
          }
        }
      });

      _.forOwn(oldProfiles, (value, key) => {
        if (oldProfiles[key].length > 0) {
          result = { ...result, [key]: value };
        }
      });

      return result;
    },
    checkUserProfiles() {
      const stakeholderId = this.companySelected.stakeholderId;

      let result = _.map(Object.keys(this.user.profiles), profile => {
        if (_.includes(this.user.profiles[profile], stakeholderId)) {
          return parseProfile(profile);
        }
      });

      return _.filter(result, element => element != undefined);
    }
  },
  computed: {
    ...mapGetters("Admin", ["companySelected"]),
    ...mapGetters("Constants", ["getConstant"]),
    ...mapGetters("Auth", ["lang", "hasModule", "isMatrix"]),
    fields() {
      let isRequired = this.$route.query.type == "single" ? true : false;

      let single = [
        {
          id: "name",
          required: true,
          type: "text",
          validation: validText(this.form.name),
          maxLength: 255
        },
        {
          id: "surname",
          required: true,
          type: "text",
          validation: validText(this.form.surname),
          maxLength: 255
        },
        {
          id: "email",
          required: true,
          type: "text",
          validation: validRFC5322(this.form.email)
        },
        {
          id: "login",
          type: "text",
          required: false,
          validation: validRFC5322(this.form.login)
        },
        {
          id: "phoneNumber",
          required: false,
          type: "text",
          validation: validPhone(this.form.phoneNumber)
        }
      ];

      let multi = [
        {
          id: "department",
          type: "select",
          required: false,
          label: "label",
          checked: false,
          options: this.departmentOptions
        },
        {
          id: "timezone",
          type: "select",
          required: isRequired,
          checked: false,
          options: this.timezones
        },
        {
          id: "language",
          type: "select",
          required: isRequired,
          checked: false,
          options: _.map(
            this.companySelected.environment.languages?.list,
            language => language
          )
        },
        {
          id: "isBoss",
          type: "boolean",
          required: isRequired,
          width: 50,
          checked: false,
          options: {
            checked: this.$t(`admin.form.yes`),
            unchecked: this.$t(`admin.form.no`)
          }
        },
        {
          id: "sendemail",
          type: "boolean",
          required: isRequired,
          checked: false,
          options: {
            checked: this.$t(`admin.form.yes`),
            unchecked: this.$t(`admin.form.no`)
          }
        },
        {
          id: "authentication",
          type: "select",
          required: true,
          label: "label",
          trackBy: "id",
          options: this.authenticationOptions
        },
        {
          id: "dateLaunchSurvey",
          type: "date",
          checked: false,
          required: true,
          disabled:
            this.$route.query.type == "single" &&
            !this.form.dateLaunchSurveyEnabled,
          locale_user: this.lang,
          min: this.tomorrow.format("YYYY-MM-DD")
        }
      ];

      let fields =
        this.$route.query.type == "single" ? single.concat(multi) : multi;

      if (this.$route.query.type == "multiple") {
        _.each(fields, field => {
          field.multiple = true;
        });
      }

      if (
        this.$route.params.id != localStorage.getItem("stakeholderId") &&
        this.$route.query.type == "single"
      ) {
        fields.push({
          id: "profile",
          type: "multiselect",
          required: isRequired,
          checked: false,
          options: _.map(this.profiles, profile => profile)
        });
      }
      if (
        this.companiesProfiles.length > 0 &&
        this.$route.params.id != localStorage.getItem("stakeholderId") &&
        this.$route.query.type == "single"
      ) {
        fields.push({
          id: "assignated_profiles",
          type: "profiles_table",
          required: false,
          options: this.companiesProfiles,
          fields: this.tableFields,
          size: 12,
          currentPage: 1
        });
      }

      return fields;
    },
    departmentOptions() {
      let departments = [];
      _.each(this.departments, department => {
        departments.push({
          id: department.stakeholderId,
          label: department.name
        });
      });
      return departments;
    },
    authenticationOptions() {
      let options = [];
      _.each(this.authenticationMethods, method => {
        options.push({
          id: method,
          label: this.$t(`admin.form.auth_options.${method}`)
        });
      });
      return options;
    },
    tomorrow() {
      return moment().add(1, "days");
    }
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) {
        this.loadCompanySelected();
      }
    },
    isCompanySelectedLoaded(value) {
      if (localStorage.getItem("managedCompanyId") && value === false) {
        this.loadCompanySelected();
      }
    }
  },
  mounted() {
    if (!this.isCompanySelectedLoaded) {
      this.loadCompanySelected();
    }

    let authenticationText = "AUTHENTICATION_PASSWORD";
    if (this.hasModule(["federation"])) {
      authenticationText += ",AUTHENTICATION_FEDERATION";
    }
    if (this.hasModule(["mfa"])) {
      authenticationText += ",AUTHENTICATION_TOTP_PASSWORD";
    }
    if (this.isMatrix) {
      authenticationText += ",AUTHENTICATION_NOAUTH";
    }

    const stakeholderCompanyId = localStorage.getItem("managedCompanyId");
    let requests = [
      getDepartments(localStorage.getItem("managedCompanyId")),
      getConstants("PROFILE_USER,PROFILE_CONTROLLER,PROFILE_ADMIN"),
      getConstants(authenticationText),
      getTimezones(),
      getCompanies(stakeholderCompanyId)
    ];

    if (this.$route.query.type == "single") {
      requests.push(
        getAdminStakeholder("people", this.$route.params.id, {
          logo: true,
          email: true,
          fiscalCode: true,
          domain: true,
          services: true,
          environment: true,
          stakeholderDepartmentId: true,
          isBoss: true,
          profiles: true,
          phoneNumber: true,
          login: true,
          authentication: true,
          dateLaunchSurvey: true
        })
      );
    }

    axios.all(requests).then(
      axios.spread(
        (
          departmentsResponse,
          profileConstants,
          authConstants,
          timezonesResponse,
          companiesResponse,
          userResponse
        ) => {
          this.timezones = timezonesResponse.data.records;
          this.departments = departmentsResponse.data.records;
          this.profiles = _.map(profileConstants.data.records, profile =>
            parseProfile(profile)
          );
          this.authenticationMethods = authConstants.data.records;
          let managedCompanies = companiesResponse.data.records;
          _.each(managedCompanies, element => {
            this.companiesProfiles.push({
              id: element.stakeholderId,
              name: element.name,
              controller: false,
              admin: false,
              user: element.stakeholderId == stakeholderCompanyId
            });
          });
          if (userResponse) {
            this.user = userResponse.data.records;
            let externalProfiles = {};
            _.each(this.user.profiles, (value, key) => {
              externalProfiles[key] = _.filter(
                value,
                profile => profile != stakeholderCompanyId
              );
            });
            this.form = {
              name: this.user.name,
              surname: this.user.surname,
              email: this.user.email,
              department: _.find(
                this.departmentOptions,
                el => el.id == this.user.stakeholderDepartmentId
              ),
              login: this.user.login,
              timezone: this.user.environment.timezone,
              language: this.user.environment.languages?.default,
              isBoss: this.user.isBoss === 1,
              sendemail:
                this.user.environment.notifications?.sendemail === "YES",
              assignated_profiles: _.mergeWith(
                { USER: [], CONTROLLER: [], ADMIN: [] },
                externalProfiles,
                (objValue, srcValue) => {
                  if (_.isArray(objValue)) {
                    return objValue.concat(srcValue);
                  }
                }
              ),
              profile: this.checkUserProfiles(),
              dateLaunchSurvey: this.user.dateLaunchSurvey.date
                ? moment(this.user.dateLaunchSurvey.date)
                    .add(1, "day")
                    .format("YYYY-MM-DD HH:mm:ss")
                : this.tomorrow.format("YYYY-MM-DD HH:mm:ss"),
              dateLaunchSurveyEnabled: this.user.dateLaunchSurvey.enabled,
              phoneNumber: this.user.phoneNumber,
              authentication:
                _.find(
                  this.authenticationOptions,
                  el => el.id == this.user.authentication
                ) ||
                _.find(
                  this.authenticationOptions,
                  el => el.id == this.getConstant("AUTHENTICATION_PASSWORD")
                )
            };
          } else {
            this.form = {
              department: "",
              timezone: "",
              language: "",
              isBoss: false,
              sendemail: false,
              profile: ""
            };
          }

          this.loaded = true;
        }
      )
    );
  }
};
</script>
